import React from "react";
import service from "./img/services.jpeg";
import ServiceSlide from "./ServiceSlide";
import { handleContextMenu } from "./const";
export default function Services() {
  let width = window.innerWidth;
  return (
    <div className="row">
      <div className={`col-md-5 bg-light-gray px-0 ${width > 500 ? 'mt-5' : 'mt-2'}`}>
        <img
        onContextMenu={handleContextMenu}
          src={service}
          alt="SLP Technologie Services"
          className="d-block w-100 h-100"
          layout="responsive"
        />
      </div>
      <div className={`col-md-7 bg-main ${width > 500 ? 'mt-5' : 'mt-2'}`}>
        <ServiceSlide/>
      </div>
    </div>
  );
}
