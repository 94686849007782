import React, { useState } from "react";
import phone from './img/phone.png';
import mail from './img/mail.png';
import location from './img/location.png';
import time from './img/time.png';
import { handleContextMenu } from "./const";
const Contact = () => {
let width = window.innerWidth;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmissionStatus("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "c4dd709c-75e6-4763-8715-a633c716a00c");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setSubmissionStatus("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setSubmissionStatus(data.message);
    }
  };
  console.log("width", width)
  return (
    <div className="row shadow-lg rounded">
      <div className={`col-md-4 bg-main text-white ${width > 500 ? "p-5" : "p-3"}`}>
        <h3>Reach Us</h3>
        <div className={`${width > 500 ? "mt-5" : "mt-3"}`}>
          <img onContextMenu={handleContextMenu} src={phone} alt="Phone" width={20} height={20} />
          <span className="mx-2">+91 86079 59954</span>
        </div>
        <div className={`${width > 500 ? "mt-5" : "mt-3"}`}>
          <img onContextMenu={handleContextMenu} src={mail} alt="Email" width={20} height={20} />
          <span className="mx-2">info@slptechnologie.com</span>
        </div>
        <div className={`${width > 500 ? "mt-5" : "mt-3"}`}>
          <img onContextMenu={handleContextMenu} src={location} alt="Location" width={20} height={20} />
          <span className="mx-2">Kapashera, New Delhi - 110097, India</span>
        </div>
        <div className={`${width > 500 ? "mt-5" : "mt-3"}`}>
          <img onContextMenu={handleContextMenu} src={time} alt="Working Hours" width={20} height={20} />
          <span className="mx-2">Mon-Sat: 9 am-6 pm, Sun: Off</span>
        </div>
      </div>
      <div className="col-md-8">
        <div className={`${width > 500  ? "p-5" : "p-2"}`}>
        {/* onSubmit={handleSubmit} */}
          <form onSubmit={onSubmit}>
            {/* <input type="hidden" name="access_key" value="c4dd709c-75e6-4763-8715-a633c716a00c" /> */}
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  type="text"
                  className={`form-control ${formData.name ? "is-valid" : ""}`}
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">{formErrors.name}</div>
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className={`form-control ${formData.email ? "is-valid" : ""}`}
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">{formErrors.email}</div>

                {/* <input
                  type="email"
                  className={`form-control ${formData.email ? "is-valid" : ""} ${formErrors.email ? "is-invalid" : ""}`}
                  id="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">{formErrors.email}</div> */}
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="country" className="form-label">Country</label>
                <input
                  type="text"
                  className={`form-control ${formData.country ? "is-valid" : ""}`}
                  id="country"
                  name="country"
                  placeholder="Enter your country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">{formErrors.country}</div>
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="tel"  
                  className={`form-control ${formData.phone ? "is-valid" : ""}`}
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">{formErrors.phone}</div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className={`form-control ${formData.message ? "is-valid" : ""}`}
                id="message"
                name="message"
                placeholder="Enter your message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">{formErrors.message}</div>
            </div>
            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
            {submissionStatus && <div className="mt-3">{submissionStatus}</div>}
          </form>
        </div>
      </div>
    </div>
  );
};


export default Contact;
