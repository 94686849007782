import Carousel from 'react-bootstrap/Carousel';
import slideOne from './img/banner-one.jpg';
import slideTwo from './img/banner-two.jpg';
import slideTheree from './img/banner-three.jpg';
import slideFour from './img/banner-four.jpg';
import phone from './img/black_phone.png'
import arrowright from './img/circleArrow.png'
import { Link } from 'react-router-dom';
import { handleContextMenu } from './const';

function Slider() {
let width = window.innerWidth;
  return (
    <Carousel>
      <Carousel.Item>
        <img onContextMenu={handleContextMenu} className='slider-mb-img' src={slideOne} alt='SLP IT Company'/>
        <Carousel.Caption >
            <h1>Users Centric {width > 500 && <br />}<span className='text-warning'>Performance</span> Focused</h1>
            <p>Transforming ideas into reality with cutting-edge{width > 500 && <br />} web and mobile apps.</p>
            <div className={width > 500 ? `mt-5` : `mt-1`}>
              <Link href="tel:+918607959954" className='bg-warning text-black slider-btn mx-1'>
                <img onContextMenu={handleContextMenu}
                  src={phone}
                  alt="Phone"
                />
                <span className="mx-2">+91 86079 59954</span>
              </Link>
              {width < 500 && <br />}
              <Link href="#contact" className="slider-btn bg-danger mx-1">
                <span className="mx-2">Contact Us</span>
                <img onContextMenu={handleContextMenu}
                className='icon-size'
                  src={arrowright}
                  alt="Contact"
                />
              </Link>
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img onContextMenu={handleContextMenu} className='slider-mb-img' src={slideTwo} alt='SLP IT Company'/>
        <Carousel.Caption >
            <h1>Users Centric {width > 500 && <br />}<span className='text-info'>Scalability </span> Focused</h1>
            <p>Transforming ideas into reality with cutting-edge{width > 500 && <br />} web and mobile apps.</p>
            <div className={width > 500 ? `mt-5` : `mt-1`}>
              <Link href="tel:+918607959954" className='bg-warning text-black slider-btn mx-1'>
                <img onContextMenu={handleContextMenu}
                  src={phone}
                  alt="Phone"
                />
                <span className="mx-2">+91 86079 59954</span>
              </Link>
              {width < 500 && <br />}
              <Link href="#contact" className="slider-btn bg-danger mx-1">
                <span className="mx-2">Contact Us</span>
                <img onContextMenu={handleContextMenu}
                className='icon-size'
                  src={arrowright}
                  alt="Contact"
                />
              </Link>
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img onContextMenu={handleContextMenu} className='slider-mb-img' src={slideTheree} alt='SLP IT Company'/>
        <Carousel.Caption >
            <h1>Users Centric {width > 500 && <br />}<span className='text-success'>Reliability </span> Focused</h1>
            <p>Transforming ideas into reality with cutting-edge{width > 500 && <br />} web and mobile apps.</p>
            <div className={width > 500 ? `mt-5` : `mt-1`}>
              <Link href="tel:+918607959954" className='bg-warning text-black slider-btn mx-1'>
                <img onContextMenu={handleContextMenu}
                  src={phone}
                  alt="Phone"
                />
                <span className="mx-2">+91 86079 59954</span>
              </Link>
              {width < 500 && <br />}
              <Link href="#contact" className="slider-btn bg-danger mx-1">
                <span className="mx-2">Contact Us</span>
                <img onContextMenu={handleContextMenu}
                className='icon-size'
                  src={arrowright}
                  alt="Contact"
                />
              </Link>
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img onContextMenu={handleContextMenu} className='slider-mb-img' src={slideFour} alt='SLP IT Company'/>
        <Carousel.Caption >
            <h1>Users Centric {width > 500 && <br />}<span className='text-danger'>Availability </span> Focused</h1>
            <p>Transforming ideas into reality with cutting-edge{width > 500 && <br />} web and mobile apps.</p>
            <div className={width > 500 ? `mt-5` : `mt-1`}>
              <Link href="tel:+918607959954" className='bg-warning text-black slider-btn mx-1'>
                <img onContextMenu={handleContextMenu}
                  src={phone}
                  alt="Phone"
                />
                <span className="mx-2">+91 86079 59954</span>
              </Link>
              {width < 500 && <br />}
              <Link href="#contact" className="slider-btn bg-danger mx-1">
                <span className="mx-2">Contact Us</span>
                <img onContextMenu={handleContextMenu}
                className='icon-size'
                  src={arrowright}
                  alt="Contact"
                />
              </Link>
            </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;